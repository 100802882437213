<template>
  <div class="update-trade-in">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
          <template v-slot:preview>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <div class="col-md-12 mb-5">
                <div class="row pl-0">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Chương trình:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="!isDisabled ? searchTradeInProgram : ''"
                          :suggestions="filteredOptionsTradeInProgram"
                          :placeholder="'tên chương trình'"
                          :limit="10"
                          @select="onSelectedTradeInProgram"
                          @change="onInputChangeTradeInProgram"
                          :disabled="!isDisabled"
                        />
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Ngày tạo:</label>
                      <span class="ml-4">{{ createdAt }}</span>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Sản phẩm:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="!isDisabled ? searchProduct : ''"
                          :suggestions="filteredOptionsProduct"
                          :placeholder="'tên sản phẩm'"
                          :limit="10"
                          @select="onSelectedProduct"
                          @change="onInputChangeProduct"
                          :disabled="!isDisabled"
                        />
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Người tạo:</label>
                      <span class="ml-4">{{ createdBy }}</span>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Khách hàng:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="!isDisabled ? searchCustomer : ''"
                          :suggestions="filteredOptionsCustomer"
                          :placeholder="'tên, SĐT khách hàng'"
                          :limit="10"
                          @select="onSelectedCustomer"
                          @change="onInputChangeCustomer"
                          :disabled="!isDisabled"
                        />
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Cửa hàng:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="!isDisabled ? searchStore : ''"
                          :suggestions="filteredOptionsStore"
                          :placeholder="'tên cửa hàng'"
                          :limit="10"
                          @select="onSelectedStore"
                          @change="onInputChangeStore"
                          :disabled="!isDisabled"
                        />
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >IMEI:</label>
                      <b-col md="6">
                        <Autosuggest
                          :model="!isDisabled ? searchImei : ''"
                          :suggestions="filteredOptionsImei"
                          placeholder="mã IMEI"
                          :limit="10"
                          @select="onSelectedImei"
                          @change="onInputChangeImei"
                          :disabled="!isDisabled"
                        />
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Trạng thái:</label>
                      <b-col md="6">
                        <b-form-select
                          class="select-style"
                          size="sm"
                          id="input-3"
                          v-model="selectedStatus"
                          :options="listStatus"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        for="input-status"
                        class="col-md-3 pl-0 font-weight-bolder"
                      >CCCD:</label>
                      <b-col md="6">
                        <UpdateCustomerInfo
                          :currentValue="customerIdentifyNo"
                          :customerId="customerId"
                          type="indentifyNo"
                          v-if="renderComponent"
                        />
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                      >Loại:</label>
                      <b-col md="6">
                        <b-form-select
                          class="select-style"
                          size="sm"
                          id="input-3"
                          v-model="selectedType"
                          :options="listType"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >Tệp đính kèm</label>
                      <b-col md="6">
                        <i
                          class="fa fa-download text-primary cursor-pointer"
                          aria-hidden="true"
                          @click="openFileModal()"
                        ></i>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div
                      class="row"
                      v-for="(item, index) in listGroupOption"
                      :key="index"
                    >
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                      >{{ item.criterialName }}</label>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="item.listItem"
                      >
                        <template v-slot:cell(amount)="row">
                          <div class="row d-flex justify-content-end">
                            <div class="d-flex justify-content-end pr-2">
                              <span v-if="row.item.operatorType === 1">(+)
                              </span>
                              <span v-if="row.item.operatorType === 2">(-)
                              </span>
                              <span v-text="convertPrice(row.item.amount)"></span>
                            </div>
                          </div>
                        </template>
                        <template v-slot:cell(selected)="row">
                          <div style="text-align: center">
                            <b-form-checkbox
                              v-if="row.item.optionType === 2"
                              id="checkbox-1"
                              v-model="row.item.selected"
                              name="checkbox-1"
                              :disabled="true"
                            >
                            </b-form-checkbox>
                            <b-form-radio
                              v-else-if="row.item.optionType === 1"
                              v-model="row.item.selected"
                              :disabled="true"
                              :name="row.item.name"
                              :value="row.item.id"
                            ></b-form-radio>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-4">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-12 pl-0"
                      >Tổng quan:</label>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                      >Giá thu trên sản phẩm:</label>
                      <span>{{ convertPrice(productBuyingPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                      >Phát sinh sau đánh giá:</label>
                      <span>{{ convertPrice(totalCriteriaPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                      >Giá thu dự kiến:</label>
                      <span>{{ convertPrice(estimationBuydingPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                      >Giá thu cuối cùng:</label>
                      <span>{{ convertPrice(finalBuyingPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-description"
                      >Ghi chú:</label>
                    </div>
                    <div class="row">
                      <b-form-textarea
                        id="textarea-description"
                        v-model="note"
                        placeholder="Ghi chú"
                        rows="5"
                      ></b-form-textarea>
                    </div>
                    <div
                      class="row"
                      style="padding-left: 0px"
                    >
                      <div class="mb-5 mt-5">
                        <button
                          type="button"
                          class="
                            btn btn-primary
                            font-weight-bolder
                            btn-sm
                            button
                            mr-4
                          "
                          @click="onSubmit"
                        >
                          Lưu phiếu
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-primary
                            font-weight-bolder
                            btn-sm
                            button
                          "
                          @click="importTradeIn"
                        >
                          Tạo phiếu nhập máy cũ
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createUser"
              v-show="false"
            >Lưu</b-button>
            <router-link
              to="/trade-in"
              tag="button"
            >
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </router-link>

            <b-modal
              ref="file-modal"
              size="lg"
              hide-footer
              title="Chỉnh sửa tệp"
              id="file-modal"
            >
              <b-row>
                <b-col cols="12">
                  <UploadFile
                    :id="tradeInId"
                    :entity="'evaluation-trade'"
                  />
                </b-col>
              </b-row>
            </b-modal>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { convertPrice } from '@/utils/common';
import { TRADE_IN_TYPE } from '@/utils/constants';
import { getToastInstance } from '@/utils/toastHelper';
import UploadFile from '@/view/modules/upload/Upload';
import UpdateCustomerInfo from '@/view/components/customers/UpdateCustomerInfo.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      form: {
        username: '',
        password: '',
      },
      selectedEmployee: null,
      selectedStatus: 1,
      listStatus: [
        { id: 1, name: 'Đã báo giá' },
        { id: 2, name: 'Đã thu cũ' },
      ],
      selectedRole: null,
      listRole: [],
      selectedId: null,
      isNew: true,
      searchEmployee: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      listTradeInProgram: [
        {
          id: 1,
          name: 'AAA',
        },
        {
          id: 2,
          name: 'BBBB',
        },
      ],
      selectedTradeInProgram: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: 'Nhập tên sản phẩm',
      filteredOptionsTradeInProgram: [],
      optionsTradeInProgram: [
        {
          data: [],
        },
      ],
      searchCustomer: 'Nhập tên, SĐT khách hàng',
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchTradeInProgram: 'Nhập tên chượng trình',
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStore: 'Nhập tên cửa hàng',
      filteredOptionsImei: [],
      optionsImei: [
        {
          data: [],
        },
      ],
      searchImei: 'Nhập mã IMEI',
      tradeInId: null,
      createdBy: '',
      createdAt: '',
      listGroupOption: [],
      listCriterialOption: [],
      fields: [
        {
          key: 'name',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'selected',
          label: 'Lựa chọn',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
      ],
      productBuyingPrice: 0,
      finalBuyingPrice: 0,
      estimationBuydingPrice: 0,
      totalCriteriaPrice: 0,
      note: '',
      selectedType: null,
      listType: TRADE_IN_TYPE,
      isDisabled: false,
      customerId: null,
      customerIdentifyNo: null,
      renderComponent: false,
    };
  },
  validations: {
    form: {},
  },
  components: {
    KTCodePreview,
    Autosuggest,
    UploadFile,
    UpdateCustomerInfo,
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thu cũ- đổi mới', route: '/trade-in' },
      { title: 'Danh sách phiếu thu cũ', route: '/trade-in' },
      { title: 'Thông tin phiếu thu cũ' },
    ]);
    this.tradeInId = this.$route.query.id;
    this.getTradeInById();
  },
  methods: {
    openFileModal() {
      this.$refs['file-modal'].show();
    },
    convertPrice,
    ...getToastInstance(),
    createUser: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;
      const employeeId = this.selectedEmployee;
      const roleId = this.selectedRole;
      let isActive = false;
      if (this.selectedStatus === 1) {
        isActive = true;
      }
      let data = {
        username: username,
        password: password,
        employeeId: employeeId,
        roleId: roleId,
        isActive: isActive,
      };

      ApiService.setHeader();
      ApiService.post('user/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess('Thêm mới tài khoản thành công!');
            setTimeout(() => {
              this.$router.push({
                path: '/users',
              });
            }, 1000);
          } else {
            this.makeToastFailure(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    fetchData: async function () {
      this.listRole = [];
      let param = {
        page: 1,
        limit: 10,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('role', paramQuery).then(({ data }) => {
        data.data.list_role.forEach((element) => {
          let item = {
            id: element.id,
            name: element.name,
          };
          this.listRole.push(item);
        });
      });
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;

      this.debounceInput();
    },
    onSelectedTradeInProgram(option) {
      this.searchTradeInProgram = option.item.name;
    },
    onInputChangeTradeInProgram(text) {
      if (!text) {
        text = '';
      }
      this.searchTradeInProgram = text;
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.name;
    },
    onInputChangeCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;
    },
    onSelectedImei(option) {
      this.searchImei = option.item.name;
    },
    onInputChangeImei(text) {
      if (!text) {
        text = '';
      }
      this.searchImei = text;
    },
    fetchProduct(textSearch) {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${textSearch}&storeId=&includeParent=false`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        if (textSearch !== this.searchProduct) {
          let nextTextSearch = this.searchProduct;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    getTradeInById: function () {
      ApiService.setHeader();
      ApiService.get(`tradeIn/${this.tradeInId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.searchTradeInProgram =
              data.data.tradeInProgramName || 'Nhập tên chương trình';
            this.searchProduct = data.data.productName || 'Nhập tên sản phẩm';
            this.searchCustomer =
              data.data.customerName || 'Nhập tên, SĐT khách hàng';
            this.customerIdentifyNo = data.data.customerIdentifyNo;
            this.customerId = data.data.customerId;
            this.searchStore = data.data.storeName || 'Nhập tên cửa hàng';
            this.searchImei = data.data.imeiCode || 'Nhập mã IMEI';
            this.selectedStatus = data.data.status;
            this.createdBy = data.data.createdBy;
            this.createdAt = data.data.createdDate;
            this.listGroupOption = data.data.listCreteriaGroup;
            this.productBuyingPrice = data.data.productBuyingPrice;
            this.totalCriteriaPrice = data.data.totalCriteriaPrice;
            this.estimationBuydingPrice = data.data.estimationBuydingPrice;
            this.finalBuyingPrice = data.data.finalBuyingPrice;
            this.note = data.data.note;
            this.selectedType = data.data.tradeInType;

            this.renderComponent = true;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    importTradeIn: function () {
      const { href } = this.$router.resolve({
        name: 'import-trade-in',
        query: { id: this.tradeInId },
      });
      window.open(href, '_blank');
    },
    onSubmit: async function () {
      const data = {
        id: this.tradeInId,
        typeTradeIn: this.selectedType,
        status: this.selectedStatus,
        note: this.note,
      };

      ApiService.setHeader();
      ApiService.put('tradeIn', data)
        .then((response) => {
          const status = response.data.status;
          const message = response.data.message;
          if (status === 1) {
            this.makeToastSuccess(message);
            // this.updateCustomerInfo();
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
  },
};
</script>

<style lang="scss">
.update-trade-in {
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .label-input {
    font-weight: 500;
  }
}
</style>
